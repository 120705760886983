"use client";
export { usePathname, useParams, redirect } from "next/navigation";
import { prepareQueryParamsToString } from "@/utils/url";
import { ReadonlyURLSearchParams, useRouter as useNextRouter, usePathname, useSearchParams } from "next/navigation";
import { useMemo } from "react";

type RouterNavigationURL =
  | string
  | {
      pathname?: string | null;
      query?: { [param: string]: string | number | undefined };
    };

type RouterNavigationOptions = {
  locale?: string;
  scroll?: boolean;
  shallow?: boolean;
};

const useClientRouter = () => {
  const router = useNextRouter();

  const searchParams = useSearchParams();
  const query = useMemo(() => getQueryParams(searchParams), [searchParams]);
  const pathname = usePathname();
  const asPath = `${global.location?.pathname}${global.location?.search}`;

  return useMemo(
    () => ({
      ...router,
      push: (url: RouterNavigationURL, options?: RouterNavigationOptions) => {
        if (options?.scroll) {
          setTimeout(() => {
            global.document.body.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 200);
        }
        if (typeof url === "string") {
          let href = url;
          if (options?.locale) {
            // TODO need to checking exists locales
            href = `/${options.locale}/${url.replace(/^\/\w\w\//, "")}`;
          }
          router.push(href, options);
          return Promise.resolve(null);
        } else if (url?.pathname) {
          const pathname = url.pathname;
          const query = prepareQueryParamsToString(url.query);
          router.push(`${pathname}?${query}`);
        }
      },
      replace: (url: RouterNavigationURL, options?: RouterNavigationOptions): Promise<any> => {
        if (typeof url === "string") {
          let href = url;
          if(options?.locale) {
            href = href.replace(/^\/\w\w\//, "");
            // TODO need to checking exists locales
            const localePrefix = `/${options.locale}${!href.startsWith('/') ? '/' : ''}`;
            href = `${localePrefix}${href}`;
          }
          if (options?.shallow) {
            // TODO next/router - shallow not implemented
            history.replaceState(null, "", href);
          } else {
            router.replace(href);
          }
          // if (options?.locale) {
          //   // TODO need to checking exists locales
          //   const href = `/${options.locale}/${url.replace(/^\/\w\w\//, "")}`;
          //   // router.replace(`/${options.locale}/${url.replace(/^\/\w\w\//, "")}`, { location: options?.locale });
          //   global.location.href = href;
          // } else {
          //   if (options?.shallow) {
          //     // TODO next/router - shallow not implemented
          //     history.replaceState(null, "", url);
          //   } else {
          //     router.replace(url);
          //   }
          // }

          return Promise.resolve(null);
        }

        router.replace("MOCK PATHCNAME REPLACE");
        return Promise.resolve(null);
      },
      reload: () => {
        global.location.href = global.location.href;
      },
      query,
      search: prepareQueryParamsToString(query),
      asPath,
      pathname,
    }),
    // 1eslint-disable-next-line react-hooks/exhaustive-deps
    [router, query, asPath, pathname]
  );
};

const getQueryParams = (searchParams: ReadonlyURLSearchParams | null) => {
  const query: { [key: string]: string | undefined } = {};

  searchParams?.forEach((value, param) => {
    query[param] = `${value || ""}` || undefined;
  });

  return query;
};

export default useClientRouter;
