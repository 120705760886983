import useClientContext from "@/context/ClientContext";
import ErrorPageLayout from "@/modules/home/components/ErrorPageLayout";
import PublicIcons from "@/components/basic/PublicIcon";
import React from "react";

const Icon = () => {
  const { isMobileSize } = useClientContext();
  return <PublicIcons name="image500" width={isMobileSize ? 200 : 256} height={isMobileSize ? 200 : 256} />;
};

interface ServerErrorProps {
  meta: any;
}

export default function ServerError(props: ServerErrorProps) {
  const { meta } = props;
  const { isMobileSize, translate } = useClientContext();

  return (
    <ErrorPageLayout
      meta={meta}
      icon={Icon}
      // TODO translate
      title={"Щось сталося!" || translate("Something happened!")}
      // TODO translate
      subtitle={"Повертайтеся незабаром, ми вже працюємо над усуненням проблеми!" || translate("Check back soon, we're already working on fixing the problem!")}
    />
  );
}
