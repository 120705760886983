"use client";

import ServerError from '@/modules/home/pages/ServerError';

export default function GlobalError({ error, reset }: { error: Error; reset: () => void }) {
  return (
    <ServerError meta={error} />
    // <html>
    //   <body>
    //     <h2>Something went wrong (global)!</h2>
    //     <button onClick={() => reset()}>Try again</button>
    //   </body>
    // </html>
  );
}
